.App {
  text-align: center;
  color: #D1D5DB;
}

h1, h2{
  color: #30AAB0; /* Set default title colors */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  display: flex;
  flex-direction: column;
}

.App > *:first-child {
  /* Cover 30% of the viewport height for Header */
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App > *:nth-child(2) {
  /* Cover 70% of the viewport height for Overview */
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overview-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; /* Assuming you want it to take up the full height of the viewport */
}

.overview-content {
  /* Your content styling here, if any */
}

.scroll-to-about-me-button {
  align-self: center;
  padding: 10px 20px;
  margin-top: 350px;
  /* Any other styling you wish to add to the button */
}

#projects {
  scrollbar-width: thin;
  scrollbar-color: #30AAB0 #191D28; 
}

#projects::-webkit-scrollbar {
  height: 8px;
}

#projects::-webkit-scrollbar-track {
  background: #191D28;
}

#projects::-webkit-scrollbar-thumb {
  background-color: #5AC8FA;
  border-radius: 8px;
}

.hover\:text-white:hover {
  color: white;
}

.hover\:shadow-lg:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.hover\:scale-110:hover {
  transform: scale(1.1);
}

.transition {
  transition-property: all;
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-in-out {
  transition-timing-function: ease-in-out;
}

.hover\:scale-110:hover {
  transform: scale(1.1);
}

.transition {
  transition-property: all;
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-in-out {
  transition-timing-function: ease-in-out;
}

.image-container img {
  transition: all 0.3s ease-in-out;
}

.image-container:hover img {
  transform: scale(1.5);
}