.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: dark-gray;
  }
  
  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 3; /* allows it to occupy available space */

  }
  
  .nav-link {
    position: relative;
    text-decoration: none;
    color: inherit; /* Initial color, you can change this */
    padding: 0 1.5rem; /* Reduced padding */
    font-size: 1.2rem; /* Increased font-size */
    transition: color 0.3s;
  }
  
  .nav-link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #30AAB0; /* Color for the underline on hover, adjust as needed */
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.5s ease-in-out;
  }
  
  .nav-link:hover::before {
    visibility: visible;
    transform: scaleX(0.7);
  }
  
  .nav-link:hover {
    color: #30AAB0; /* Color for the text on hover, adjust as needed */
    animation: waveEffect 0.5s forwards;
  }
  
  @keyframes waveEffect {
    0% { color: #303736; }
    50% { color: #4A7085; } /* Adjusted to an intermediate color between start and end for a smoother wave effect */
    100% { color: #30AAB0; }
  }


  html {
    scroll-behavior: smooth;
  }

  .logo-topleft {
    margin-right: 10rem;
    cursor: pointer;
    transition: opacity 0.3s;
  }

  .language-selector {
    position: relative; /* set as relative to position dropdown */
    cursor: pointer; /* to indicate clickability */
    opacity: 0;
 }
  
  /* .language-selector:hover {
    opacity: 0;
    color: #C0C0C0;
    text-shadow: 0px 0px 5px #C0C0C0;
  } */
  
  /* .language-dropdown {
    position: absolute;
    top: 100%; 
    right: 0; 
    background-color: #333; 
    border: 1px solid #555; 
    z-index: 10;
    transform: translateY(10px);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    pointer-events: none;
  }
  
  .language-selector:hover .language-dropdown,
.language-selector:focus-within .language-dropdown {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto; 
}

  .language-dropdown a {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s;
  }
  
  .language-dropdown a:hover {
    background-color: #f4f4f4;
  }
  
  .language-dropdown img {
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
  } */